/* -------------------------------------------------------------------------- */
/*                                   Leaflet                                  */
/* -------------------------------------------------------------------------- */

.leaflet-bar{
  box-shadow: var(--#{$variable-prefix}box-shadow);
  a {
    &, &:hover {
      background-color: var(--#{$variable-prefix}leaflet-bar-bg);
      color: var(--#{$variable-prefix}body-color);
      border-bottom-color: var(--#{$variable-prefix}border-color);
    }
    &.leaflet-disabled {
      background-color: var(--#{$variable-prefix}200);
    }
  }
}
.marker-cluster-small{
  background-color: rgba($primary, 0.2);
  div{
    margin: 5px;
    background-color: $primary;
    color: #fff;
  }
}
.marker-cluster-medium{
  background-color: rgba($warning, 0.2);
  div{
    margin: 5px;
    background-color: $warning;
    color: #fff;
  }
}
.marker-cluster-large{
  background-color: rgba($success, 0.2);
  div{
    margin: 5px;
    background-color: $success;
    color: #fff;
  }
}
.leaflet-popup-content-wrapper, .leaflet-popup-tip{
  background-color: var(--#{$variable-prefix}leaflet-popup-content-wrapper-bg);
  box-shadow: $box-shadow-lg;
  border-radius: $border-radius;
}
.leaflet-popup-close-button{
  right: 3px !important;
  top: 3px !important;
}
.leaflet-marker-icon{
  height: auto !important;
  width: auto !important;
}

.leaflet-control-attribution {
  display: none;
}
.leaflet-top, .leaflet-bottom{
  z-index: 999;
}

.leaflet-left{
  .leaflet-control{
    margin-left: $card-spacer-x;
  }
}
.leaflet-right{
  .leaflet-control{
    margin-right: $card-spacer-x;
  }
}
.leaflet-top{
  .leaflet-control{
    margin-top: $card-spacer-x;
  }
}
.leaflet-bottom{
  .leaflet-control{
    margin-bottom: $card-spacer-x;
  }
}